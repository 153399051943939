import React, {useState} from 'react'

import "./continue-modal.scss"
import icon from "assets/images/save.svg"

import Button from 'components/atoms/Button'
import Modal from 'components/molecules/Modal'

export default function ContinueModal({ onContinueClick, onCancelClick }) {
  const [isOpen, setIsOpen] = useState(true);

  const handleContinueClick = () => {
    onContinueClick()
    setIsOpen(false)
  }

  const handleCancelClick = () => {
    onCancelClick()
    setIsOpen(false)
  }

  return (
    <Modal className="m-continue-modal" isOpen={isOpen}>
      <div className="m-continue-modal__message">
        <img className="m-continue-modal__icon" src={icon} alt=""/>
        <h2 className="m-continue-modal__heading">Continue Game?</h2>
        <p className="m-continue-modal__body">Looks like you didn't finish your last game.</p>
      </div>

      <div className="m-continue-modal__buttons">
        <Button className="m-continue-modal__button" text="Continue" color="blue" onClick={handleContinueClick} />
        <button type="button" className="m-continue-modal__link" onClick={handleCancelClick}>No, thanks</button>
      </div>
    </Modal>
  )
}

const automaCards = [
  {
    id        : 1,
    group     : 'b',
    frontImage: '/cards/1-front.png',
    backImage : '/cards/1-back.png'
  },
  {
    id        : 2,
    group     : 'b',
    frontImage: '/cards/2-front.png',
    backImage : '/cards/2-back.png'
  },
  {
    id        : 3,
    group     : 'a',
    frontImage: '/cards/3-front.png',
    backImage : '/cards/3-back.png'
  },
  {
    id        : 4,
    group     : 'a',
    frontImage: '/cards/4-front.png',
    backImage : '/cards/4-back.png'
  },
  {
    id        : 5,
    group     : 'a',
    frontImage: '/cards/5-front.png',
    backImage : '/cards/5-back.png'
  },
  {
    id        : 6,
    group     : 'a',
    frontImage: '/cards/6-front.png',
    backImage : '/cards/6-back.png'
  },
  {
    id        : 7,
    group     : 'b',
    frontImage: '/cards/7-front.png',
    backImage : '/cards/7-back.png'
  },
  {
    id        : 8,
    group     : 'c',
    frontImage: '/cards/8-front.png',
    backImage : '/cards/8-back.png'
  },
  {
    id        : 9,
    group     : 'b',
    frontImage: '/cards/9-front.png',
    backImage : '/cards/9-back.png'
  },
  {
    id        : 10,
    group     : 'a',
    frontImage: '/cards/10-front.png',
    backImage : '/cards/10-back.png'
  },
  {
    id        : 11,
    group     : 'a',
    frontImage: '/cards/11-front.png',
    backImage : '/cards/11-back.png'
  },
  {
    id        : 12,
    group     : 'c',
    frontImage: '/cards/12-front.png',
    backImage : '/cards/12-back.png'
  },
  {
    id        : 13,
    group     : 'a',
    frontImage: '/cards/13-front.png',
    backImage : '/cards/13-back.png'
  },
  {
    id        : 14,
    group     : 'c',
    frontImage: '/cards/14-front.png',
    backImage : '/cards/14-back.png'
  },
  {
    id        : 15,
    group     : 'c',
    frontImage: '/cards/15-front.png',
    backImage : '/cards/15-back.png'
  },
  {
    id        : 16,
    group     : 'a',
    frontImage: '/cards/16-front.png',
    backImage : '/cards/16-back.png'
  },
  {
    id        : 17,
    group     : 'a',
    frontImage: '/cards/17-front.png',
    backImage : '/cards/17-back.png'
  },
  {
    id        : 18,
    group     : 'c',
    frontImage: '/cards/18-front.png',
    backImage : '/cards/18-back.png'
  },
  {
    id        : 19,
    group     : 'a',
    frontImage: '/cards/19-front.png',
    backImage : '/cards/19-back.png'
  },
  {
    id        : 20,
    group     : 'a',
    frontImage: '/cards/20-front.png',
    backImage : '/cards/20-back.png'
  },
  {
    id        : 21,
    group     : 'a',
    frontImage: '/cards/21-front.png',
    backImage : '/cards/21-back.png'
  },
  {
    id        : 22,
    group     : 'c',
    frontImage: '/cards/22-front.png',
    backImage : '/cards/22-back.png'
  }
];

export default automaCards;
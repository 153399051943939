import React from 'react'
import cx from 'classnames'

import Card from 'components/atoms/Card'

import './deck.scss'

export default function Deck({
  className,
  card,
  cardFace='front',
  emptyText='Empty'
}) {
  const classes = cx(className, "m-deck");

  return (
    <div className={classes}>
      <div className="m-deck__slot">
        {card && <Card className="m-deck__card" {...card} isFlipped={ cardFace === 'back' }></Card>}
        {!card && <div className="m-deck__text">{emptyText}</div>}
      </div>
    </div>
  )
}

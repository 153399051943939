import React from 'react'
import cx from "classnames"

import './button.scss'

export default function Button({
  className,
  text,
  color='purple',
  onClick
}) {
  const classes = cx(className, "a-button", {
    [`a-button--${color}`]: color
  });

  return (
    <button
      className={classes}
      type="button"
      onClick={onClick}
    >{text}</button>
  )
}

export function SecondaryButton({...props}) {
  props.color = 'orange';
  return <Button {...props}></Button>
}

export function SuccessButton({...props}) {
  props.color = 'green';
  return <Button {...props}></Button>
}
import React from 'react'
import cx from "classnames"

import "./progress-bar.scss"

export default function ProgressBar({ className, progress, total }) {
  const percentage = Math.round((progress/total) * 100)

  const classes = cx(className, "a-progress-bar")

  return (
    <div className={classes}>
      <div className="a-progress-bar__bar" style={{width: `${percentage}%`}}></div>
    </div>
  )
}

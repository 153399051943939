import React from 'react'
import cx from 'classnames'

import './era.scss'

export default function Era({ className, isRail }) {
  const classes = cx(className, 'a-era', {
    'a-era--rail': isRail
  });

  return (
    <div className={classes}>
      <div className="a-era__status"></div>
    </div>
  )
}

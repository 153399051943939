export default function shuffle(array) {
  let unshuffled = array.length, t, i;

  while (unshuffled) {
    i = Math.floor(Math.random() * unshuffled--);

    t = array[unshuffled];
    array[unshuffled] = array[i];
    array[i] = t;
  }

  return array;
}
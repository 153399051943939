import React from 'react'
import cx from 'classnames'

import './modal.scss'

export default function Modal({ className, children, isOpen=false }) {
  const classes = cx(className, "m-modal", {
    'm-modal--open': isOpen
  });

  return (
    <aside className={classes}>
      <div className="m-modal__container u-container">
        <div className="m-modal__wrapper">
          <div className="m-modal__content">{children}</div>
        </div>
      </div>

      <div className="m-modal__overlay"></div>
    </aside>
  )
}

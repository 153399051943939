import React, {useState} from 'react'
import useLocalStorageState from 'utils/functions/useLocalStorageState'

import './game.scss'
import automaCards from "utils/data/automa-cards"
import shuffle from "utils/functions/shuffle"

import logo from 'assets/images/mautoma.svg'

import Button, { SecondaryButton } from 'components/atoms/Button'
import ProgressBar from 'components/atoms/ProgressBar'
import Era from 'components/atoms/Era'
import Deck from 'components/molecules/Deck'
import ContinueModal from 'components/molecules/ContinueModal'

export default function Game() {
  const [modalIsOpen]                 = useState(openContinueModal());
  const [drawPile, setDrawPile]       = useLocalStorageState('mautoma:draw-pile', setupDrawDeck());
  const [discardPile, setDiscardPile] = useLocalStorageState('mautoma:discard-pile', []);
  const [era, setEra]                 = useLocalStorageState('mautoma:era', 'canal');

  const topDrawCard    = drawPile[0];
  const topDiscardCard = discardPile[0];

  const drawNextCard = () => {
    if ( drawPile.length === 0 ) return;
  
    const newDrawPile = [...drawPile];
    const nextCard = newDrawPile.shift();

    setDrawPile(newDrawPile);
    setDiscardPile([nextCard, ...discardPile]);
  }

  const resetDeck = () => {
    setDrawPile(setupDrawDeck());
    setDiscardPile([]);
  }

  const resetGame = () => {
    resetDeck();
    setEra('canal');
  }

  const startRailEra = () => {
    resetDeck();
    setEra('rail');
  }

  return (
    <main className="p-game">
      <ProgressBar className="p-game__progress" progress={discardPile.length} total={discardPile.length + drawPile.length} />
      <Era className="p-game__status" isRail={era === 'rail'} />

      <div className="p-game__content">
        <div className="p-game__decks">
          <Deck className="p-game__deck" card={topDiscardCard} emptyText="Start"></Deck>
          <Deck className="p-game__deck" card={topDrawCard} cardFace="back"></Deck>
        </div>

        <div className="p-game__buttons">
          {drawPile.length > 0 &&
            <Button className="p-game__button" text="Draw Card" onClick={drawNextCard} />}

          {(drawPile.length === 0) &&
            <Button className="p-game__button" text="Restart Game" color={era === 'rail' && 'orange'} onClick={resetGame} />}

          {(drawPile.length === 0 && era === 'canal') &&
            <SecondaryButton className="p-game__button" text="Start Rail Era" onClick={startRailEra} />}
        </div>
      </div>

      <p className="p-game__credit">
        solo mode by <a href="http://www.mautoma.com/mautomas/brass-birmingham" target="_blank" rel="noreferrer"><img src={logo} alt=""/></a>
      </p>

      {modalIsOpen && <ContinueModal onContinueClick={() => ''} onCancelClick={resetGame} />}
    </main>
  )
}

function setupDrawDeck() {
  const cards = automaCards;

  const groupA = shuffle(cards.filter(card => {
    return card.group === 'a';
  })).slice(0, 5);

  const groupB = shuffle(cards.filter(card => {
    return card.group === 'b';
  })).slice(0, 4);

  const groupC = shuffle(cards.filter(card => {
    return card.group === 'c';
  })).slice(0, 4);

  const topCards       = shuffle([groupA.shift(), groupB.shift(), groupC.shift()]);
  const remainingCards = shuffle([...groupA, ...groupB, ...groupC]);

  return [...topCards, ...remainingCards];
}

function openContinueModal() {
  const localDrawPile    = JSON.parse(window.localStorage.getItem('mautoma:draw-pile'));
  const localDiscardPile = JSON.parse(window.localStorage.getItem('mautoma:discard-pile'));
  const localEra         = JSON.parse(window.localStorage.getItem('mautoma:era'));

  if (!localEra) return;

  if (localDrawPile.length && !localDiscardPile.length && localEra === 'canal') return false;

  return true;
}
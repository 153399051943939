import './styles/app.scss';

import Game from 'pages/Game';

function App() {
  return (
    <Game></Game>
  );
}

export default App;

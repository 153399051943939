import React from 'react'
import cx from "classnames"

import './card.scss'

export default function Card({
  className,
  frontImage,
  backImage,
  isFlipped
}) {
  const image = isFlipped ? backImage : frontImage;
  const classes = cx(className, "a-card");

  return (
    <div className={classes}>
      <div className="a-card__face" style={{backgroundImage: `url(${image})`}}></div>
    </div>
  )
}
